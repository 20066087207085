import { TranslateService } from '@ngx-translate/core';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPhoto } from '@libs/shared/profile/photo';

export type GenderWoman = 8;
export type GenderMan = 1;

export type Gender = GenderMan | GenderWoman;

export class IProfile implements IBaseProfile {
    profile_id: number;
    username?: string;
    sex?: Gender;
    match_sex?: number;
    birthdate?: string;
    membership_type_id?: MembershipType;
    profile_extended?: IProfileExtended;
    elite?: string;
    is_elite?: number;
    private_album_count?: number;
    new_count?: number;
    verified_photo_count?: number;
    age?: number;
    cityName?: string;
    countryName?: string;
    numberOfPhotos?: number;
    online_count?: number;
    stateName?: string;
    wideStateName?: string;
    requester_friend_status?: any;
    target_friend_status?: any;
    bookmarked_count?: number;
    uuid?: string;
    has_requested_photo_verification?: boolean;
    has_requested_info?: boolean;
    is_complete?: boolean;
    geolocation?: IProfileGeoLocation;
    public_album?: IPhoto[];
    had_previous_membership_purchase?: string;

    photos?: IPhoto[];
    photo?: IPhoto;
    liked?: boolean;
    private_album?: IPhoto[];
    height1?: number;
    body_type?: number;
    hair_color?: number;

    reported?: boolean;
    origin?: number;
    status?: string;
    favorited?: number;
    profiletype?: string;
    country_id?: string;
    state_id?: string;
    city_id?: number;
    headline?: string;
    general_description?: string;

    constructor(profileId: number) {
        this.profile_id = profileId;
    }

    static getLocation(profile: IProfile, translate: TranslateService): string {
        if (profile === undefined) {
            return '';
        }

        if (!profile.cityName || !profile.stateName) {
            return '';
        }

        return translate.instant('modules.main.pages.profile.user_location', {
            city: profile.cityName,
            province: profile.stateName,
        });
    }
}

export interface IBaseProfile {
    profile_id: number;
    username?: string;
    sex?: Gender;
    age?: number;
    cityName?: string;
    stateName?: string;
    wideStateName?: string;
    favorited?: number;
    headline?: string;
    photo?: IPhoto;
    status?: string;
    elite?: string;
    is_complete?: boolean;
    membership_type_id?: number;
    online_count?: number;
    general_description?: string;
    public_album?: IPhoto[];
    verified_photo_count?: number;
    new_count?: number;
    profile_extended?: IEditableProfileExtended;
}

export interface IProfileExtended extends IBaseProfileExtended {
    what?: number;
    has_read_our_rules?: number;
}

export interface IProfileGeoLocation {
    lat: number;
    lon: number;
}

export interface IEditableProfileExtended extends IBaseProfileExtended {
    //
}

interface IBaseProfileExtended {
    description?: string;
    height1?: number;
    body_type?: number;
    ethnicity?: number;
    hair_color?: number;
    eye_color?: number;
    smoke?: number;
    drink?: number;
    relationship?: number;
    have_children?: number;
    education?: number;
    occupation?: number;
    happy_to_travel?: number;
    select_one?: number;
    income?: number;
    net_worth?: number;
}

export interface IEditableProfileFields {
    country_id: string;
    state_id: string;
    city_id: number;
    username?: string;
    birthdate?: string;
    headline?: string;
    general_description?: string;
    match_sex?: number;
    profile_extended: IEditableProfileExtended;
}
