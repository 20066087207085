import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewStyle } from '@libs/components/submenu-v2/interfaces/list-view-style.type';
import { INewUiState } from '@libs/store/new-ui/interfaces/new-ui-state.interface';

const newUiStateFeature = createFeatureSelector<INewUiState>('newUiState');

export const selectIsAttemptingLogin = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => {
        return state.isAttemptingLogin;
    },
);

export const selectSearchLocationOption = createSelector(
    newUiStateFeature,
    (state: INewUiState): number => {
        return state.searchLocationOption;
    },
);

export const selectPreferredViewStyle = createSelector(
    newUiStateFeature,
    (state: INewUiState): ViewStyle => {
        return state.viewStyle;
    },
);

export const selectIsSavingNewPassword = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => {
        return state.isSavingNewPassword;
    },
);

export const selectIsDownloadingLists = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => {
        return state.isDownloadingLists;
    },
);

export const selectHasRedirectedOnSession = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => {
        return state.hasRedirectedOnSession;
    },
);

export const selectIsRefreshButtonVisible = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => {
        return state.isRefreshButtonVisible;
    },
);

export const selectListFirstUpdateTimestamp = createSelector(
    newUiStateFeature,
    (state: INewUiState): number => {
        return state.listFirstUpdateTimestamp;
    },
);

export const selectIsUpsellVisible = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => state.isUpsellVisible,
);

export const selectIsWebSocketReconnected = createSelector(
    newUiStateFeature,
    (state: INewUiState): boolean => state.isWebSocketReconnected,
);
