/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

////////////////////////////////////////////////////////////////////////////
////                                                                    ////
////             THIS FILE HAS BEEN GENERATED AUTOMAGICALLY             ////
////                                                                    ////
////////////////////////////////////////////////////////////////////////////

export const Config = {
    "branch": "stable",
    "version": "v1.31.0.31",
    "name": "production",
    "mailDomain": "meupatrocinio.com",
    "mailPrefix": "payer_",
    "v2URL": "https://app.meuprincipe.com/",
    "serverIp": "https://api.meuprincipe.com/",
    "realtimeServerIp": "https://notifications.mbeta.info/",
    "imagesServer": "https://images.meuprincipe.com/",
    "emailLinksDomain": "links.meupatrocinio.com",
    "emailLinksDomainAndroid": "https://dating.meupatrocinio.com",
    "listsTTL": 600000,
    "payment": {
        "server": "https://oppwa.com/v1/",
        "token": "OGE4Mzk0YzI1MzEyZmQ1MzAxNTMxOTlkMWJmNDI5NTd8akJ4V3hwUzI=",
        "entityId": "8a8394c25312fd53015319a7d1792972",
        "currency": "BRL",
        "boletoState": "SP",
        "boletoCity": "São Paulo",
        "boletoStreet": "Av. Paulista, 1636 - cj 706, Bela Vista",
        "boletoCEP": "01310-200",
        "boletoStreetNumber": "1636",
        "boletoComplement": "cj 706",
        "boletoDistrict": "Bela Vista",
        "boletoStreetOnly": "Av. Paulista"
    },
    "paymentBrands": [
        "VISA",
        "MASTER",
        "AMEX",
        "ELO"
    ],
    "gameTrial": {
        "enableDeactivateGame": true,
        "enableMessageGame": false
    },
    "moip": {
        "pubkey": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj6jSokglfHoR/Ruc8ZSE\nBc6mlrJlHP14P9WY68PA8I4msxOxCRBP5HogCEu5tREFNkVTjvoZyTZGO+mR8zrj\n5LJUuxJFSg7D6Snjzf1PES1vwXgDxoN+5fX73vPk+3/dewaA4q51NXDvFxQxMZCy\npAEbK8/kZAtFyIxdmCbhBR5CA9ZOP/ndck/E1BIJn5htUr5WAqmq34UgRpT8ppPN\nC5inCYkxNsQmmE6j6bU2jiHdUIc/6TrdysFxAghKlWStOrVyPxjPzDgVTWr32DpY\n8WCwPajVM1GOgnoeP319pWcvhXO/jGlEKZWZgWw3RS8mXsctdTFhmky0XrrqBecK\nnQIDAQAB\n-----END PUBLIC KEY-----",
        "url": "https://api.moip.com.br",
        "token": "U05ZREdVTElST0lXV1FHQVJLT0g2UTJMREtVU1pFVUU6OEJFRllDWUVQQUFHVEtWVUtTSEFNWFJRUDFVWVFJRTJMMzg3Q0ZMUw==",
        "production": true,
        "shopUrl": "ecmoip*MEUPAT"
    },
    "enablePushv2": true,
    "development": false,
    "showLogs": {
        "downloads": false,
        "device": false,
        "notifications": false,
        "routing": false,
        "redux": false,
        "fireBase": false,
        "location": false,
        "keyboard": false,
        "images": false
    },
    "throttledResponse": 403,
    "GOOGLE_TAG_MANAGER_ID": "GTM-T62CB7P",
    "gpsEnabled": false,
    "translationEnabled": false,
    "defaultPlanDurationIndex": 2,
    "feedbackNagbarPatchMinVersion": 30,
    "isAgeVisibleAvailable": false,
    "isLocationVisibleAvailable": false,
    "isPremiumSettingsAvailable": true,
    "isReferralAvailableOnAndroid": false,
    "isPhotoVerifyAvailableOnAndroid": false,
    "referralFriendshipDayEnabled": false,
    "verifyPhotoModalTimeLapse": 300000,
    "mpUrlContact": "https://www.meuprincipe.com/contato",
    "installmentsPercentage": 30,
    "hideSpanishOption": true,
    "billingAddressInExpressApproval": true,
    "cepIntegrationEndPoint": "https://viacep.com.br",
    "imagesFolderBaseUrl": "/assets/img/",
    "isBoostEnabled": false,
    "isBoostForBabiesEnabled": false,
    "supportLink": "https://meuprincipe.com/faq/",
    "reduxName": "mp-redux-v28",
    "boostBFFUrl": "https://api.meuprincipe.com/boost-bff"
};