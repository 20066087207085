import { Action, createReducer, on } from '@ngrx/store';

import { ViewStyle } from '@libs/components/submenu-v2/interfaces/list-view-style.type';
import {
    setHasRedirectedOnSession,
    setIsAttemptingLogin,
    setIsDownloadingLists,
    setPreferredViewStyle,
    setSearchLocationOption,
    setIsRefreshButtonVisible,
    setListFirstUpdateTimestamp,
    setIsUpsellVisible,
    setIsWebSocketReconnected,
} from '@libs/store/new-ui/actions';
import { INewUiState } from '@libs/store/new-ui/interfaces/new-ui-state.interface';
import { newUiInitialState } from '@libs/store/new-ui/state';
import { setIsSavingNewPassword } from '@libs/store/password/actions';

const newUiReducer = createReducer(
    newUiInitialState,
    on(setSearchLocationOption,
        (
            state: INewUiState,
            { searchLocationOption }: { searchLocationOption: number },
        ): INewUiState => {
            return {
                ...state,
                searchLocationOption,
            };
        },
    ),
    on(setIsAttemptingLogin, (
        state: INewUiState,
        { isAttemptingLogin }: { isAttemptingLogin: boolean },
    ): INewUiState => {
        return {
            ...state,
            isAttemptingLogin,
        };
    }),
    on(setPreferredViewStyle, (
        state: INewUiState,
        { viewStyle }: { viewStyle: ViewStyle },
    ): INewUiState => {
        return {
            ...state,
            viewStyle,
        };
    }),
    on(setIsSavingNewPassword, (
        state: INewUiState,
        { isSavingNewPassword }: { isSavingNewPassword: boolean },
    ): INewUiState => {
        return {
            ...state,
            isSavingNewPassword,
        };
    }),
    on(setIsDownloadingLists, (
        state: INewUiState,
        { isDownloadingLists }: { isDownloadingLists: boolean },
    ): INewUiState => {
        return {
            ...state,
            isDownloadingLists,
        };
    }),
    on(setHasRedirectedOnSession, (
        state: INewUiState,
    ): INewUiState => {
        return {
            ...state,
            hasRedirectedOnSession: true,
        };
    }),
    on(setIsRefreshButtonVisible, (
        state: INewUiState,
        { isRefreshButtonVisible }: { isRefreshButtonVisible: boolean },
    ): INewUiState => {
        return {
            ...state,
            isRefreshButtonVisible,
        };
    }),
    on(setListFirstUpdateTimestamp, (
        state: INewUiState,
        { listFirstUpdateTimestamp }: { listFirstUpdateTimestamp: number },
    ): INewUiState => {
        return {
            ...state,
            listFirstUpdateTimestamp,
        };
    }),
    on(setIsUpsellVisible, (
        state: INewUiState,
        { isOpen }: { isOpen: boolean },
    ): INewUiState => {
        return {
            ...state,
            isUpsellVisible: isOpen,
        };
    }),
    on(setIsWebSocketReconnected, (
        state: INewUiState,
        { isWebSocketReconnected: isWebSocketReconnected }: { isWebSocketReconnected: boolean },
    ): INewUiState => {
        return {
            ...state,
            isWebSocketReconnected: isWebSocketReconnected,
        };
    }),
);

export function reducer(state: INewUiState, action: Action): INewUiState {
    return newUiReducer(state, action);
}
